import axios from 'axios';

import { getHomeRouteForLoggedInUser } from '@/auth/utils'
let API_URL = ''
// /*dev*/const API_URL = 'http://192.168.1.34:5000/api/auth/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/auth/';

if (process.env.NODE_ENV === "production") {
  API_URL = 'https://ats.sattvahuman.in/api/auth/';
} else {
  API_URL = 'http://192.168.1.34:5000/api/auth/';
}

class AuthService {
    async login(user) {
      try{
        console.log("inside try")
        const res = await axios.post(API_URL + 'signin', user,
        {
          "Accept": "*/*",
          "Content-Type": "application/json"
        })
        console.log(res)
        //console.log(res.data)
        return res.data
      }
      catch(err) {
        console.log(err.response)
        return err.response
      }  
    }
    logout() {
        localStorage.removeItem(user);
    }
    async register(user) {
        const res = await axios.post(API_URL + 'signup', {
            emp_id: user.emp_id,
            email: user.email,
            password: user.password,
            roles: user.roles,
            first_name: user.first_name,
            last_name: user.last_name,
            contact_no: user.contact_no,
            status: user.status,
            location: user.location,
            mgr_id: user.mgr_id,
            doj: user.doj
        });
        console.log(res.data);
    }
}
export default new AuthService();