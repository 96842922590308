<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <sattva-logo />
        <h2 class="brand-text text-primary ml-1">Sattva</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Sattva Human Portal 👋
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="xxxxxx@sattvahuman.com"
                  />
                  <small id="uname" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!--    <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small id="pwd" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SattvaLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import AuthService from '@/services/auth-service';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import firebaseMessaging from './../../../firebase'
//import firebase from 'firebase'
import userService from "@/services/user-service";

//import { askForPermissioToReceiveNotifications } from './push-notification'

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    SattvaLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      //email: '',
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      //URL: process.env.VUE_APP_URL,
      // validation rules
      required,
      email,
      //notifications: [],
    };
  },
  computed: {
    
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    
  },
  
  methods: {
    login() {
      let bodyContent = {
                email: this.email,
                password: this.password
           };
      AuthService.login(bodyContent).then(res =>{
        ////console.log(res.data.data.message)
        if ( res == undefined ) {
          document.getElementById('pwd').innerHTML = 'Error!! Please Contact Administrator.'
          return
        }
        if(res && res.status === "OK" ) {
            //console.log("response",res.data.roles[0])
            const userData = res.data;
            console.log('get home route: ', getHomeRouteForLoggedInUser(userData.roles[0]))
            console.log("userData", userData)
            // console.log(userData.ability)
            this.updateAbilities(userData.abilities);
          //   if (res.data.roles[0] === "ADMIN" || res.data.roles[0] === "MASTER") {
          //     userData.ability = [
          //       {
          //         action: "manage",
          //         subject: "all",
          //       },
          //     ];
          //     ////console.log("ability",ability)
          //     // this.$ability.update(userData.ability);
          //     this.updateAbilities(userData.ability);
          //     //userData.push(ability)
          // } else if (res.data.roles[0] === "ACCOUNT-MANAGER" || res.data.roles[0] === "TALENT-ADVISOR-SOURCER" || res.data.roles[0] === "TALENT-ADVISOR") {
          //      userData.ability = [
          //      {
          //         action: "manage",
          //         subject: "all",
          //      },
               
          //       /*{
          //         action: 'read',
          //         subject: 'ACL',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'pages-profile',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'Auth',
          //       },
          //       {
          //         action: 'manage',
          //         subject: 'Job',
          //       },
          //       {
          //         action: 'manage',
          //         subject: 'Todo',
          //       },
          //       {
          //         action: 'manage',
          //         subject: 'Calendar',
          //       },
          //       {
          //         action: 'manage',
          //         subject: 'Candidate',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'Dashboard'
          //       },
          //       {
          //         action: 'read',
          //         subject: 'SETTING'
          //       }*/
          //     ];
          //     //userData.push(ability)
          //     this.updateAbilities(userData.ability);
          //     // this.$ability.update(userData.ability);
          // } else if (res.data.roles[0] === "HR" || res.data.roles[0] === "MIS") {
          //     // userData.ability = [
          //     //   {
          //     //     action: "manage",
          //     //     subject: "all",
          //     //   }
          //     // ];
          //     userData.ability = [
          //       {
          //         action: "read",
          //         subject: "AppsList",
          //       },
          //       // {
          //       //   action: "read",
          //       //   subject: "Job",
          //       // },
          //       {
          //         action: "read",
          //         subject: "my_candidates",
          //       },
          //       {
          //         actione: "read",
          //         subject: "Dashboard"
          //       },
          //       {
          //         action: 'read',
          //         subject: 'Auth',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'AdminDashboard',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'RevenueDashboard',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'MainDashboard',
          //       },
          //       {
          //         action: 'read',
          //         subject: 'NAV',
          //       },
          //     ];
          //     ////console.log("ability",ability)
          //     // this.$ability.update(userData.ability);
          //     this.updateAbilities(userData.ability);
          //     //userData.push(ability)
          // }
            
            useJwt.setToken(res.data.accessToken)
            useJwt.setRefreshToken(res.data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(userData))
            const expiresIn = Math.floor(Date.now() / 1000) + parseInt((userData.expireIn))
            //console.log(Math.floor(Date.now() / 1000))
            localStorage.setItem('expireIn', JSON.stringify(expiresIn))
            localStorage.setItem('emp_id', JSON.stringify(userData.emp_id))
            const lastPageUrl = window.sessionStorage.getItem('lastPage');

            if (lastPageUrl && lastPageUrl !== '/login') {
              console.log('lastPageUrl', lastPageUrl)
              // Navigate to the last page
              this.$router.push(lastPageUrl);
            } else {
              this.$router.replace(getHomeRouteForLoggedInUser(res.data.roles[0]))
              // Navigate to a default page
              // ...
            }
            
            
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Welcome ${userData.first_name || userData.username}`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully logged in as ${userData.roles[0]}. Now you can start to explore!`,
              },
            });

            userService.getAllUsersDdActiveInactive().then(res => {
              //console.log('get all users act inact',res)
              localStorage.setItem('allusers', JSON.stringify(res.data))
            })

            if(process.env.NODE_ENV === "production") {
              //console.log('Requesting permission...');
      
              Notification.requestPermission().then((permission) => {
              if (permission === 'granted') 
                //console.log('Notification permission granted.');

                firebaseMessaging.getToken({ vapidKey: "BAcJIq-NKg6GE9iGhSX_o_P7bDOSupcvLwqP3NTlXfy4ONM3VF_0_yKwcIc3hHMFYvoKQ3tTy4EYRS3FnfnQ3AI" })
                .then((currentToken) => {
                  if (currentToken) {
                    //console.log('client token', currentToken)
                    const token = {
                      token: currentToken
                    }
                    const id = JSON.parse(localStorage.getItem('userData')).id
                    userService.setToken(id, token).then(res => {
                      if(res.status === "OK") {
                        //console.log("FCM Token set successfully", res.data)
                      }
                      else {
                        //console.log("Token Set Failed")
                      }
                    })
                    
                  } else {
                    //console.log('No registration token available. Request permission to generate one.');
                  }
                })
                .catch((err) => {
                  //console.log('An error occurred while retrieving token. ', err);
                })
              })
            }
          
          }
          else if ( res.status === 400 && res.data.mesage === "Invalid Password!") 
          {
            document.getElementById('pwd').innerHTML = res.data.message
          }
          else if ( res.status === 400 && res.data.mesage === "User Not Found.") 
          {
            document.getElementById('uname').innerHTML = res.data.message
          }
          
          else {
            document.getElementById('pwd').innerHTML = 'Invalid username or password'
          }
      })
     
    },
    updateAbilities(abilities) {
      if (abilities && Array.isArray(abilities)) {
          this.$ability.update(abilities);
      } else {
          console.error("Invalid abilities format:", abilities);
          // Optionally set a default or restricted set of abilities
          this.$ability.update([{ action: 'read', subject: 'all' }]);
      }
    }
  },
  mounted () {
    ////console.log('Firebase cloud messaging object', this.$messaging)
  },
  created () {
    //console.log('Login Page base url', process.env.VUE_APP_URL)
    //console.log('Login Page base url from node', process.env.NODE_ENV)
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
